body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --dark-grey: #282c34;
  --light-grey: #aeb0b4;
  --white: #ffffffde;
  --accent-color:  #e69167;

  --ranch: #3ACAB5;
  --cheddar-sour-cream: #22C3E1;
  --cheddar: #FABF4F;
  --bbq: #8C406B;
  --sour-cream-onion: #5ECA62;
  --original: #EA002A;
  --buffalo: #F4903B;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
