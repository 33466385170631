$breakpoints: (
  small: 1px,
  medium: 640px,
  large: 980px,
  x-large: 1230px,
  xx-large: 1440px,
);

$primary-red: #F0021A;
$off-white: #FEFBEF;
$orange: #FD7309;
$orange-disabled: #FDB67B;
$yellow: #f5c944;
$blue: rgb(73, 151, 216);
$black: #000;
$red: red;
$spotify: #1DB954;
$spotify-light: #1ED760;

$font-pinup:'Pinup', sans-serif;
$font-body: "Andes", sans-serif;

@import 'scss/mixins';
@import 'scss/animations';
@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/forms';

html {
  overflow: hidden;
}

body {
  background: $off-white;
  font-family: $font-body;
  font-size: 80%;

  // -- Scroll identifier ---
  overflow: auto;
  position: relative;
  height: 100dvh;

  @include up-to-breakpoint(medium) {
    &:after {
      background: linear-gradient(transparent 0%, $off-white 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 1.5rem;
      pointer-events: none;
      position: fixed;
      width: 100vw;
    }
  }
}

.App-page { height: 100vh; }

.header {
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &:before {
    top: 0;
    bottom: 0;
    background: #69bc47;
  }


  &:after {
    top: 0;
    background: $blue;
    height: 277px;
    border-bottom: 1.5px solid #2a4c1d;
  }

  svg {
    height: 103%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: auto;
    z-index: 1;

    &.large { display: none; }
  }

  @include up-to-breakpoint(medium) {
    padding-bottom: 80%;

    &.page-splash {
      // padding-bottom: 120%;

      svg { top: 0; }
    }
  }

  @include breakpoint(medium) {
    background-position: 0 -76.7px;
    overflow: hidden;
    height: 618px;
    padding-bottom: 0;

    img {
      height: 634px;
      width: 1027.23px;
      max-width: 1027.23px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include breakpoint(medium) {
    img{
      &.mobile { display: none; }
      &.large {
        display: block;
        width: 100%;
        max-width: 100%;
        min-width: 1190px;
      }
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Loading {
    animation: App-logo-spin infinite 5s linear;
  }
} *

.App-template-wrapper {
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

.App-content-inner-container {
  align-items: center;
  display: flex;
  flex-direction: column;

  &.splash-page {
    h2 {
      font-size: 2.1rem;
      font-family: $font-pinup;
      line-height: 1.2;
      margin: 2rem 1rem;
    }

    p {
      font-weight: normal;
      font-size: .9rem;
      font-family: $font-pinup;
      padding: 0 2rem;

      @include breakpoint(medium) {
        max-width: 24rem;
        padding: 0;
        font-size: 1rem;
      }
    }
  }

}

p {
  font-size: 1.2rem;
  font-family: $font-pinup;
}

.actions {
  width: 100%;

  &.fixed {
    position: sticky;
    bottom: 2rem;
    z-index: 1;

    .button { margin-top: 0; }
  }
}

.button {
  background: $primary-red;
  border-radius: 17px;
  border: 3px solid $primary-red;
  color: $off-white;
  cursor: pointer;
  display: block;
  font-family: $font-pinup;
  font-size: 1.4rem;
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  transition: all .25s ease;
  max-width: 100%;
  width: 100%;

  &:hover {
    background:$off-white;
    color: $primary-red;
  }

  &.selected {
    background:$off-white;
    color: $primary-red;
  }

  &.artist-button {
    padding: .6rem;
    margin: .3rem;
    font-size: 1.3rem;
    white-space: wrap;
    width: fit-content;
    white-space: nowrap;
    max-width: 500px;
    background: transparent;
    color: $blue;
    border-color: $blue;

    &.selected {
      background: $blue;
      color: $off-white;
    }
  }

  &:disabled { cursor: default; }

  &.orange-button {
    background: $orange;
    border: 3px solid $orange;

    &:hover,
    &:focus,
    &:active {
      background:$off-white;
      color: $orange;
    }

    // to override hover styling
    &:disabled {
      color: $off-white;
      background: $orange-disabled;
      border-color: $orange-disabled;
    }
  }

  &.blue-button {
    background: $blue;
    border: 3px solid $blue;

    &:hover,
    &:focus,
    &:active {
      background:$off-white;
      color: $blue;
    }
  }

  &.grey-button {
    background: #898989;
    border: 3px solid #898989;

    &:hover,
    &:focus,
    &:active {
      background:$off-white;
      color: #898989;
    }
  }

  &.spotify-button {
    align-items: center;
    background: $spotify;
    border: 3px solid $spotify;
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    svg {
      color: #fff;
      margin-right: .5rem;
    }

    &:hover,
    &:focus,
    &:active {
      background:$off-white;
      color: $spotify-light;
      border-color: $spotify-light;

      svg {
        color: $spotify-light;
      }
    }
  }
}

.App-form-label {
  font-size: 1.25rem;
  margin-top: 2rem;
  transition: all .35s ease-out;
  max-width: 600px;
  text-align: left;
  width: 100%;
}

.App-text-input {
  border: 2px solid var(--light-grey);
  padding: 1.5rem 0;
  margin-top: .5rem;
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin: 0;
}

.title {
  color: $black;
  font-family: $font-pinup;
  font-size: 3.25em;
  line-height: 1.2;
  text-align: center;

  &.small { font-size: 2.5em; }
}

.orange { color: $orange; }

.artists-list-wrapper{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: center;
}

.footer {
  align-items: center;
  color: $black;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  padding: 2rem 1rem;
  text-align: center;
  width: 100%;

  p {
    font-family: $font-body;
    font-size: inherit;
    font-weight: 300;
    margin: 0 0 .3rem 0;
  }

  a {
    color: $black;
    font-weight: bold;
  }
}

.Results-inner-wrapper {
  text-align: center;
}

.Results-text-wrapper {
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;

  h1.title {
    font-size: 1.8rem;
    margin: 1.5rem 1rem;
    text-transform: none;
  }

  h2 {
    line-height: 1.65;
    font-size: 1.65rem;
  }

  .result-img {
    border-radius: 20px;
    height: auto;
    margin: 0 auto;
    // max-width: 440px;
    width: 100%;

    &.is-maximized {
      max-height: 100dvh;
      position: fixed;
      top: 0;
      left: 50%;
      width: auto;
      transform: translate(-50%, 0);
      z-index: 1000;
    }
  }

  .result-img-screen {
    background: $blue;
    bottom: 0;
    content: '';
    display: block;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 1;
  }

  .add-actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;

    .button {
      white-space: nowrap;
      margin-top: .2rem;
      padding: 0 .8rem;
      height: 65px;
    }

    .add-to-apple {
      img {
        height: auto;
        margin-bottom: 1rem;
        min-height: 95px;
        width: auto;
      }
    }
  }

  .important {
    @extend .title, .small;
    font-size: 1.4rem;
    margin-top: 1rem;

    &.post-call {
      margin: 2rem auto;
      max-width: 300px;

      span {
        color: $blue;
      }
    }
  }

  .share {
    margin: 0 auto 2.5rem;
  }

  p { margin: 1rem 0 .25rem; }
}

.Results-inner-wrapper img {
  height: 275px;
}

.favorite-artists {
  font-family: $font-pinup;
  font-size: 1.2rem;
  margin: 0 auto 2rem;
  max-width: 400px;
  width: 100%;

  strong {
    margin-bottom: .25rem;
    display: block;
    color: $orange;
  }
}

.loader {
  max-width: 26rem;
  margin: 0 auto;
  width: 100%;

  .title { margin-top: 1rem; }
}

.loading-bar {
  background-color: $orange;
  border-radius: 17px;
  height: 30px;
  margin: 20px;
  overflow: hidden;
}

.progress-indicator {
  background-color: $yellow;
  width: 0%;
  height: 100%;

  animation-name: loading-fixed;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;

  &.infinite {
    animation-iteration-count: infinite;
  }
}

@keyframes loading-fixed {
  from { width: 0%; }
  to { width: 100%; }
}

@keyframes loading {
  from { width: 0%; }
  to { width: 100%; }
}

.loading-characters {
  height: 15rem;
  max-width: 500px;
  margin: auto;
  position: relative;

  &.characters-results {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 15rem;
    padding: 2rem 2rem 3rem;
    max-width: 98%;

    h1 { margin-bottom: 0rem; }

    p {
      color: $blue;
      font-size: 1.4rem;
    }
  }
}

.four-o-four {
  padding: 2rem 1rem;
  margin: 0 auto;
}
