.form {
  max-width: 100%;
  margin: 0 auto;
  width: 100%;

  .title {
    margin-bottom: 2rem;
  }

  & > h1  { margin-top: 2rem; }
}

.form-row {
  display: flex;
  flex-direction: column;

  &:last-child { margin-bottom: 0; }

  &.with-check {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;

    label {
      font-size: 1rem;
      color: $black;
      text-align: left;

      &:hover { cursor: pointer; }
    }

    &:last-child { margin-bottom: 0; }
  }

  @include breakpoint(medium) {
    flex-direction: row;
    margin-bottom: 1rem;
  }
}

input {
  &[type="date"],
  &[type="text"],
  &[type="checkbox"],
  &[type="email"] {
    border: 4px solid $black;
    border-radius: 17px;
    color: $black;
  }

  &[type="checkbox"] {
    border-radius: 12px;
    height: 2rem;
    margin-right: 1rem;
    min-width: 2rem;
    position: relative;
    width: 2rem;

    &:after {
      display: block;
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: center;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em #000;
    }

    &:hover { cursor: pointer; }
    &:checked {
      &:after {
        transform: scale(1) translate(2px, 3px);
      }
    }
  }

  &[type="date"],
  &[type="text"],
  &[type="email"] {
    font-size: 1.4rem;
    padding: 1rem;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;

    &:focus { color: $black; }

    @include breakpoint(medium) {
      margin-bottom: 0;
      margin-right: 1rem;

      &:last-child { margin-right: 0; }
    }
  }

  &[type="date"]{
    &:before {
      content: #{attr(placeholder)};
      color: black;
      margin-right: 0.5em;
    }

    @include up-to-breakpoint(medium) {
      &:focus:before{ content: "" !important; }
    }
  }

  @include breakpoint(medium) {
    &.zip {
      max-width: 200px;
    }
  }

  &.has-error {
    border-color: $red;
  }
}

.errors {
  background: $red;
  border-radius: 17px;
  color: $off-white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 1rem;
}

input[type="date"]:before {
  content: #{attr(placeholder)} !important;
  color: black;
  margin-right: 0.5em;
}
// input[type="date"]:focus:before{
//   content: "";
// }
