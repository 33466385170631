@keyframes wiggle{
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes wiggle-inverted {
  25% {
    transform: scaleX(-1) rotate(5deg);
  }
  50% {
    transform: scaleX(-1) rotate(-5deg);
  }
  75% {
    transform: scaleX(-1) rotate(5deg);
  }
  100% {
    transform: scaleX(-1) rotate(-5deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Can-shake {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(15deg);
  }
}
